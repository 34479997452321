import React, { ReactElement, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import { testIds } from "@timhortons/common/src/utils/formHelpers";
import { dateFormat, timeFormat, toTitleCase } from "@timhortons/common/src/utils/dateFormat";
import Icon, { IconNames } from "@timhortons/common/src/components/atoms/icon";
import Loader from "@timhortons/common/src/components/molecules/loader";
import Button, { ButtonType } from "@timhortons/common/src/components/atoms/button";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { Cell, HeaderCell, Row, Table } from "@timhortons/common/src/components/molecules/table";
import { FilterStatus } from "@timhortons/common/src/modules/rev/components/dashboards/organisms/actionPlanHeader";
import CommonPagination from "@timhortons/common/src/modules/rev/organisms/commonPagination";
import { SelectedFilterDataModel } from "@timhortons/common/src/models/rev/actionPlan";
import Jumbotron, {
  ErrorType
} from "@timhortons/common/src/modules/rev/components/dashboards/organisms/jumbotron";
import { LpActionPlanTableModel } from "@timhortons/common/src/models/lp/actionPlan";
import {
  ILPActionPlansError,
  ILPActionPlansLoading
} from "@timhortons/common/src/redux/lp/reducers/actionPlan";
import { Roles } from "@timhortons/common/src/constants/roles";

interface IProps {
  isIndividualLogin: boolean;
  getActionPlans: Function;
  actionPlansList: LpActionPlanTableModel;
  translate: any;
  onCreatePlan(actionPlanId: number, auditId: number, createPlan: boolean, version: string): void;
  paginationHandler(val: number): void;
  handleSortingOrder(val: string, columnName: string): void;
  pageNum: number;
  loading: ILPActionPlansLoading;
  error: ILPActionPlansError;
  selectedLpFilterData?: SelectedFilterDataModel;
  userRole: Roles;
}

export enum LPTableColumns {
  auditDate = "auditDate",
  auditTime = "auditTime"
}
function LpActionPlanTable(props: IProps): ReactElement {
  const {
    translate,
    actionPlansList,
    onCreatePlan,
    paginationHandler,
    handleSortingOrder,
    isIndividualLogin,
    pageNum,
    loading,
    error,
    selectedLpFilterData,
    userRole
  } = props;

  const [auditDateSelected, setAuditDateSelected] = useState(true);
  const [auditTimeSelected, setAuditTimeSelected] = useState(false);
  const storeActionPlanTableHeader = [
    {
      name: translate(LPTableColumns.auditDate),
      isSortable: true,
      selected: auditDateSelected
    },
    {
      name: translate(LPTableColumns.auditTime),
      isSortable: true,
      selected: auditTimeSelected
    },
    { name: translate("auditedBy"), isSortable: false },
    { name: translate("status"), isSortable: false },
    { name: translate("completion"), isSortable: false },
    { name: translate("view"), isSortable: false }
  ];
  const auditorActionTableHeader = [
    { name: translate("storeId"), isSortable: false },
    { name: translate("storeNameTable"), isSortable: false },
    {
      name: translate(LPTableColumns.auditDate),
      isSortable: true,
      selected: auditDateSelected
    },
    {
      name: translate(LPTableColumns.auditTime),
      isSortable: true,
      selected: auditTimeSelected
    },
    { name: translate("auditedBy"), isSortable: false },
    { name: translate("status"), isSortable: false },
    { name: translate("completion"), isSortable: false },
    { name: translate("view"), isSortable: false }
  ];
  const auditorHeadActionTableHeader = [
    { name: translate("storeId"), isSortable: false },
    { name: translate("storeNameTable"), isSortable: false },
    {
      name: translate(LPTableColumns.auditDate),
      isSortable: true,
      selected: auditDateSelected
    },
    {
      name: translate(LPTableColumns.auditTime),
      isSortable: true,
      selected: auditTimeSelected
    },
    { name: translate("auditedBy"), isSortable: false },
    { name: translate("status"), isSortable: false },
    { name: translate("completion"), isSortable: false },
    { name: translate("view"), isSortable: false }
  ];
  const handleStatus = (value: string): Object => {
    if (value === FilterStatus.completed) {
      return styles.completedStatus;
    } else if (value === FilterStatus.notStarted) {
      return styles.notStartedStatus;
    } else return styles.overdueStatus;
  };
  const sortHandler = (val: "asc" | "desc", columnName: string) => {
    if (columnName === translate(LPTableColumns.auditDate)) {
      setAuditDateSelected(true);
      setAuditTimeSelected(false);
    } else {
      setAuditDateSelected(false);
      setAuditTimeSelected(true);
    }
    handleSortingOrder(val, columnName);
  };
  const getViewIcon = (actionPlanId: number, auditId: number, version: string): JSX.Element => {
    return (
      <>
        <Icon
          color={colorPallete.grey2}
          onPress={() => onCreatePlan(actionPlanId, auditId, false, version)}
          name={IconNames.eyeView}
          size={16}
        />
      </>
    );
  };

  const getLpHeaderComp = (headerItem: any, index: number) => {
    return (
      <HeaderCell
        key={index}
        columnSelected={headerItem.selected}
        title={headerItem.name}
        sortable={headerItem.isSortable}
        columnName={headerItem.name}
        sortFunction={(val: "asc" | "desc") => sortHandler(val, headerItem.name)}
        fontWeight={FontWeight.Regular}
        textSize={TextSize.Regular}
        titleStyle={{ color: colorPallete.grey1 }}
        sortIconStyle={styles.sortIcon}
      />
    );
  };
  const getIndividualTableRow = (rowItem: any) => {
    return (
      <>
        <Cell cellStyle={styles.title} title={rowItem.storeId} />
        <Cell cellStyle={styles.title} title={rowItem.storeName} />
        <Cell cellStyle={styles.title} title={dateFormat(rowItem.auditDate)} />
        <Cell cellStyle={styles.title} title={timeFormat(rowItem.auditDate)} />
        <Cell cellStyle={styles.title} title={rowItem.auditBy} />
        <Cell>
          <Text
            textStyle={handleStatus(rowItem.status)}
            testId={testIds.createPlanButton}
            textSize={TextSize.ExtraRegular}
            fontWeight={FontWeight.Bold}
          >
            {toTitleCase(rowItem.status)}
          </Text>
        </Cell>
      </>
    );
  };

  const getStoreTableRow = (rowItem: any) => {
    return (
      <>
        <Cell cellStyle={styles.title} title={dateFormat(rowItem.auditDate)} />
        <Cell cellStyle={styles.title} title={timeFormat(rowItem.auditDate)} />
        <Cell cellStyle={styles.title} title={rowItem.auditBy} />
        <Cell>
          <Text
            textStyle={handleStatus(rowItem.status)}
            testId={testIds.createPlanButton}
            textSize={TextSize.ExtraRegular}
            fontWeight={FontWeight.Bold}
          >
            {toTitleCase(rowItem.status)}
          </Text>
        </Cell>
      </>
    );
  };
  return (
    <View style={styles.tableContainer}>
      <Table>
        <Row header rowStyle={styles.rowStyle}>
          {userRole === Roles.AuditorLP
            ? auditorActionTableHeader.map((headerItem, index) =>
                getLpHeaderComp(headerItem, index)
              )
            : userRole === Roles.StoreAccess
            ? storeActionPlanTableHeader.map((headerItem, index) =>
                getLpHeaderComp(headerItem, index)
              )
            : auditorHeadActionTableHeader.map((headerItem, index) =>
                getLpHeaderComp(headerItem, index)
              )}
        </Row>
        {error.getLpActionPlans ? (
          <Jumbotron type={ErrorType.GatewayError} title={translate("apiErrorMessage")} />
        ) : loading.getLpActionPlans ? (
          <Loader type="component" />
        ) : actionPlansList && actionPlansList.actionPlans.length > 0 ? (
          <>
            {actionPlansList &&
              actionPlansList.actionPlans.map((rowItem: any, index: number) => {
                return (
                  <Row key={index}>
                    {userRole === Roles.HeadLP || isIndividualLogin
                      ? getIndividualTableRow(rowItem)
                      : getStoreTableRow(rowItem)}

                    <Cell title={`${rowItem.completionPercentage}%`} />
                    <Cell>
                      {isIndividualLogin ? (
                        getViewIcon(rowItem.actionPlanId, rowItem.auditId, rowItem.version)
                      ) : rowItem.status === "COMPLETED" ? (
                        getViewIcon(rowItem.actionPlanId, rowItem.auditId, rowItem.version)
                      ) : (
                        <Button
                          onPress={() =>
                            onCreatePlan(
                              rowItem.actionPlanId,
                              rowItem.auditId,
                              true,
                              rowItem.version
                            )
                          }
                          fontWeight={FontWeight.Regular}
                          textSize={TextSize.ExtraRegular}
                          buttonStyles={styles.createPlanBtn}
                          type={ButtonType.Primary}
                          title={translate("createPlan")}
                          textStyles={styles.createPlanText}
                        />
                      )}
                    </Cell>
                  </Row>
                );
              })}
            <Cell cellStyle={styles.paginationStyle}>
              <CommonPagination
                handlePagination={paginationHandler}
                pageNum={pageNum}
                totalRecords={actionPlansList.totalRecords}
              />
            </Cell>
          </>
        ) : (selectedLpFilterData && selectedLpFilterData.fromDate) ||
          (selectedLpFilterData && selectedLpFilterData.toDate) ||
          (selectedLpFilterData && selectedLpFilterData.search) ||
          (selectedLpFilterData && selectedLpFilterData.status) ? (
          <Jumbotron
            type={ErrorType.NoDataFound}
            title={translate("noActionPlanFiltersTitle")}
            subTitle={translate("noActionPlanFiltersSubTitle")}
          />
        ) : (
          <Jumbotron type={ErrorType.NoDataFound} title={translate("noActionPlanAvailable")} />
        )}
      </Table>
    </View>
  );
}
export default LpActionPlanTable;
const styles = StyleSheet.create({
  title: {
    color: colorPallete.black4,
    textAlign: "center"
  },
  sortIcon: {
    paddingHorizontal: 3
  },
  createPlanBtn: {
    backgroundColor: colorPallete.variant1,
    borderRadius: 8,
    paddingVertical: 6,
    paddingHorizontal: 16
  },
  createPlanText: {
    color: colorPallete.red2
  },

  completedStatus: {
    color: colorPallete.green1
  },
  notStartedStatus: {
    color: colorPallete.red3
  },
  overdueStatus: {
    color: colorPallete.yellow1
  },
  paginationStyle: { borderBottomLeftRadius: 8, borderBottomRightRadius: 8 },
  tableContainer: {
    flex: 1,
    paddingBottom: 20,
    borderRadius: 8,
    backgroundColor: colorPallete.white3,
    borderColor: colorPallete.grey19,
    borderWidth: 1
  },
  rowStyle: {
    backgroundColor: colorPallete.grey9,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  }
});
