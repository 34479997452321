import { call, put, takeLatest } from "redux-saga/effects";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { ILPActionPlanData } from "@timhortons/common/src/modules/lp/components/dashboard/actionPlans";
import {
  FilterDataModel,
  LpActionPlanHeaderModel,
  LpAcknowledgementModel,
  LpActionPlanCategoryDataModel,
  LpActionPlanTableModel,
  LpActionPlanAccordionDataModel,
  LpPostActionstepsModel
} from "@timhortons/common/src/models/lp/actionPlan";
import { LPActionPlansAction } from "@timhortons/common/src/redux/lp/actions/actionPlans";
import { LPActionPlanRepository } from "@timhortons/common/src/repositories/lp/actionPlan";

function* getLpActionPlansDataWorkerSaga(value: IFluxStandardAction<ILPActionPlanData>) {
  const { payload } = value;
  try {
    const response: LpActionPlanTableModel = yield call(
      LPActionPlanRepository.getLpActionPlans,
      payload
    );
    yield put(LPActionPlansAction.getLpActionPlansSuccess(response));
  } catch (error) {
    Logger.error("lp-action-plans", error, "getLpActionPlansDataWorkerSaga");

    yield put(LPActionPlansAction.getLpActionPlansError(error));
  }
}
function* getLpFilterDataWorkerSaga(
  value: IFluxStandardAction<{ storeCode: string; empId: number; isIndividualLogin: boolean }>
) {
  const { payload } = value;
  try {
    const response: FilterDataModel = yield call(LPActionPlanRepository.getLpFilterData, payload);
    yield put(LPActionPlansAction.getLpFilterDataSuccess(response));
  } catch (error) {
    Logger.error("lp-action-plans", error, "getLpFilterDataWorkerSaga");

    yield put(LPActionPlansAction.getLpFilterDataError(error));
  }
}
// function* updateLpActionPlanStatusWorkerSaga(value: IFluxStandardAction<{ actionPlanId: number }>) {
//   const { payload } = value;
//   try {
//     yield call(LPActionPlanRepository.getUpdateActionPlanStatus, payload);
//     yield put(LPActionPlansAction.updateLpActionPlanStatusSuccess());
//   } catch (error) {
//     Logger.error(JSON.stringify("lp/actionPlans", error, "updateLpActionPlanStatusWorkerSaga"));
//     yield put(LPActionPlansAction.updateLpActionPlanStatusError(error));
//   }
// }

function* getLpActionPlanDetailWorkerSaga(
  value: IFluxStandardAction<{ actionPlanId: number; version?: string }>
) {
  const { payload } = value;
  try {
    const response: LpActionPlanHeaderModel = yield call(
      LPActionPlanRepository.getLpActionPlanDetails,
      payload
    );
    yield put(LPActionPlansAction.getLpActionPlanDetailSuccess(response));
  } catch (error) {
    Logger.error("lp-action-plans", error, "getLpActionPlanDetailWorkerSaga");

    yield put(LPActionPlansAction.getLpActionPlanDetailError(error));
  }
}

function* getLpCategoryDataWorkerSaga(
  value: IFluxStandardAction<{ auditId: number; actionPlanId: number; version?: string }>
) {
  const { payload } = value;
  try {
    const response: LpActionPlanCategoryDataModel[] = yield call(
      LPActionPlanRepository.getLpActionPlanCategoryTableData,
      payload
    );
    yield put(LPActionPlansAction.getLpCategoryDataSuccess(response));
  } catch (error) {
    Logger.error("lp-action-plans", error, "getLpCategoryDataWorkerSaga");

    yield put(LPActionPlansAction.getLpCategoryDataError(error));
  }
}
function* getLpAccordianDataWorkerSaga(
  value: IFluxStandardAction<{
    categoryId: number;
    actionPlanId: number;
    auditId: number;
    version?: string;
  }>
) {
  const { payload } = value;
  try {
    const response: LpActionPlanAccordionDataModel = yield call(
      LPActionPlanRepository.getLpActionPlanAccordianData,
      payload
    );
    yield put(LPActionPlansAction.getLpActionPlanAccordionDataSuccess(response));
  } catch (error) {
    Logger.error("lp-action-plans", error, "getLpAccordianDataWorkerSaga");

    yield put(LPActionPlansAction.getLpActionPlanAccordionDataError(error));
  }
}

function* getLpOwnerDatatWorkerSaga(value: IFluxStandardAction<{ storeId: string }>) {
  try {
    const { payload } = value;
    const response: LpAcknowledgementModel = yield call(
      LPActionPlanRepository.getLpOwnerData,
      payload
    );
    yield put(LPActionPlansAction.getLpOwnerDataSuccess(response));
  } catch (error) {
    Logger.error("lp-action-plans", error, "getLpOwnerDatatWorkerSaga");

    yield put(LPActionPlansAction.getLpOwnerDataError(error));
  }
}

function* postLpActionStepsWorkerSaga(value: IFluxStandardAction<FormData>) {
  const { payload } = value;
  try {
    let data: LpPostActionstepsModel = yield call(
      LPActionPlanRepository.postLpActionSteps,
      payload
    );
    yield put(LPActionPlansAction.postLpActionStepsSuccess(data));
  } catch (error) {
    Logger.error("lp-action-plans", error, "postLpActionStepsWorkerSaga");

    yield put(LPActionPlansAction.postLpActionStepsError(error));
  }
}
function* updateLpActionStepsWorkerSaga(
  value: IFluxStandardAction<{
    subCategoryId: number;
    actionPlanId: number;
    body: FormData;
    version?: string;
  }>
) {
  const { payload } = value;
  try {
    let data: LpPostActionstepsModel = yield call(
      LPActionPlanRepository.updateLpActionSteps,
      payload
    );
    yield put(LPActionPlansAction.updateLpActionStepsSuccess(data));
  } catch (error) {
    Logger.error("lp-action-plans", error, "updateLpActionStepsWorkerSaga");

    yield put(LPActionPlansAction.updateLpActionStepsError(error));
  }
}
function* lpActionPlanWatcherSaga() {
  yield takeLatest(LP.ActionPlans.GET_LP_ACTION_PLANS.LOADING, getLpActionPlansDataWorkerSaga);
  yield takeLatest(LP.ActionPlans.GET_LP_FILTER_DATA.LOADING, getLpFilterDataWorkerSaga);
  yield takeLatest(
    LP.ActionPlans.GET_LP_ACTION_PLAN_HEADER_DATA.LOADING,
    getLpActionPlanDetailWorkerSaga
  );
  yield takeLatest(LP.ActionPlans.GET_LP_CATEGORY_TABLE_DATA.LOADING, getLpCategoryDataWorkerSaga);
  yield takeLatest(
    LP.ActionPlans.GET_LP_ACTION_PLAN_ACCORDION_DATA.LOADING,
    getLpAccordianDataWorkerSaga
  );
  // yield takeLatest(
  //   LP.ActionPlans.UPDATE_LP_ACTION_PLAN_STATUS.LOADING,
  //   updateLpActionPlanStatusWorkerSaga
  // );
  yield takeLatest(LP.ActionPlans.GET_LP_OWNER_DATA.LOADING, getLpOwnerDatatWorkerSaga);
  yield takeLatest(LP.ActionPlans.POST_LP_ACTION_STEPS.LOADING, postLpActionStepsWorkerSaga);
  yield takeLatest(LP.ActionPlans.UPDATE_LP_ACTION_STEPS.LOADING, updateLpActionStepsWorkerSaga);
}

export default lpActionPlanWatcherSaga;
