import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { AuditActions } from "@timhortons/common/src/redux/lp/actions/audits";
import { AuditsSelectors } from "@timhortons/common/src/redux/lp/selectors/audits";
import { LpAppealsSelectors } from "@timhortons/common/src/redux/lp/selectors/appeals";
import { LpAppealsAction } from "@timhortons/common/src/redux/lp/actions/appeals";
import { StyleSheet, ScrollView, View } from "react-native";
import { FontWeight, Text, TextSize } from "@timhortons/common/src/components/atoms/text";
import { Roles } from "@timhortons/common/src/constants/roles";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Loader from "@timhortons/common/src/components/molecules/loader";
import Audit from "@timhortons/common/src/modules/lp/components/dashboard/audits";
import LpAppeals from "@timhortons/common/src/modules/lp/components/dashboard/appeals";
import ActionPlans from "@timhortons/common/src/modules/lp/components/dashboard/actionPlans";
import Reports from "@timhortons/common/src/modules/lp/components/dashboard/reports";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { IState } from "@timhortons/common/src/store/interfaces";
import {
  ILpAppealsError,
  ILpAppealsLoading
} from "@timhortons/common/src/redux/lp/reducers/appeals";
import { LpCreateNewAppeal } from "@timhortons/common/src/models/lp/appeals";
import { navigation } from "@timhortons/common/src/utils/navigation";
import { Pathnames } from "@timhortons/common/src/utils/pathnameInterface";
import { getComponent } from "@timhortons/web/src/utils/routeHelper";
import NavButtons, { INavLinkOption } from "@timhortons/common/src/components/organisms/navButton";
import { allRoutesNames } from "@timhortons/common/src/utils/allRouteNames";
import PageNotFound from "@timhortons/common/src/components/molecules/errorsScreen/pageNotFound";
import { testIds, validations } from "@timhortons/common/src/utils/formHelpers";
import { IAuditError } from "@timhortons/common/src/redux/lp/reducers/audits";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import { LpAuditInfoModel } from "@timhortons/common/src/models/lp/audits";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import { checkAllowedRoles } from "@timhortons/common/src/utils/checkLoginRoles";

interface IProps {
  history: any;
  route?: any;
  translate?: any;
  user?: IUserDetails;
  createLpNewAppealResponse?: LpCreateNewAppeal;
  createLpNewAppeal?: Function;
  lpAppealError: ILpAppealsError;
  lpAppealLoading: ILpAppealsLoading;
  checkAuthID: Function;
  checkAuth: LpAuditInfoModel;
  error: IAuditError;
  resetAuthID: Function;
}

export interface SelectedMonthFilter {
  month: {
    id: number;
    label: string;
  };
  year: number;
}
export enum AuditType {
  STANDARD = "STANDARD"
}
export interface ILpCreateNewAppeals {
  auditId: number;
  auditorId: number;
  storeCode: number;
  isIndividualLogin: boolean;
  version: string;
}
let mv = false;
function Dashboard(props: IProps): ReactElement {
  const {
    translate,
    history,
    user,
    createLpNewAppealResponse,
    createLpNewAppeal,
    lpAppealError,
    lpAppealLoading,
    error,
    checkAuthID,
    checkAuth,
    resetAuthID
  } = props;
  const checkAccess = (role: Roles) => {
    if (
      role === Roles.StoreAccess ||
      role === Roles.OperationManager ||
      role === Roles.SeniorOpsManager ||
      role === Roles.CountryManager ||
      role === Roles.ClusterHeadofOps ||
      role === Roles.AreaManager
      // role === Roles.AreaTrainer
    ) {
      return false;
    } else {
      return true;
    }
  };
  const [isIndividualLogin, setIndividualLogin] = useState(checkAccess(user?.roles[0]));
  const [showSCErrorModal, setShowSCErrorModal] = useState(false);
  const isFocused = useFocusedHook();
  const [currentComponent, setCurrentComponent] = useState<string>(
    user?.roles?.includes(Roles.AreaTrainer)
      ? Pathnames.lpReportsList
      : !checkAccess(user?.roles[0])
      ? Pathnames.lpActionPlansList
      : Pathnames.lpAuditsList
  );
  let comp = getComponent();
  useEffect(() => {
    if (comp === "audits") {
      comp = Pathnames.lpAuditsList;
      setCurrentComponent(comp);
    } else if (comp === "appeals") {
      comp = Pathnames.lpAppealsList;
      setCurrentComponent(comp);
    } else if (comp === "actionPlans") {
      comp = Pathnames.lpActionPlansList;
      setCurrentComponent(comp);
    } else if (comp === "reports") {
      comp = Pathnames.lpReportsList;
      setCurrentComponent(comp);
    }
  }, [comp]);
  const [modalVisible, setModalVisible] = useState(false);

  const setModalState = (): void => {
    setModalVisible(!modalVisible);
  };

  const handleAppealSubmit = async (auditId: number, empID: string, version?: string) => {
    createLpNewAppeal({
      auditId: auditId,
      auditorId: empID,
      storeCode: user && user.storeCode,
      isIndividualLogin: isIndividualLogin,
      version: version
    });
  };

  useEffect(() => {
    setShowSCErrorModal(false);
    if (currentComponent === Pathnames.lpAppealsList && isFocused) {
      if (checkAuth && checkAuth.storeName !== null && error.lpCheckAuth === null) {
        let searchParams = { id: checkAuth.auditId, type: checkAuth.auditType };
        resetAuthID();
        navigation(history, Pathnames.testAudit, searchParams);
      } else if (error.lpCheckAuth !== null) {
        setShowSCErrorModal(!showSCErrorModal);
      }
    }
  }, [checkAuth, error.lpCheckAuth]);

  useEffect(() => {
    setShowSCErrorModal(false);

    if (currentComponent === Pathnames.lpAppealsList) {
      LocalStorage.setStorage("appealAuth", "authorized");
      if (
        createLpNewAppealResponse &&
        createLpNewAppealResponse.status === "success" &&
        lpAppealError.createLpNewAppeal === null
      ) {
        let searchParams = {
          id: createLpNewAppealResponse.data,
          appealerId: createLpNewAppealResponse.appealerId,
          version: createLpNewAppealResponse.version
        };
        navigation(history, Pathnames.detailedAppeal, searchParams);
      } else if (lpAppealError.createLpNewAppeal !== null) {
        LocalStorage.setStorage("appealAuth", "not authorized");
        setShowSCErrorModal(!showSCErrorModal);
      }
    }
  }, [createLpNewAppealResponse, lpAppealError.createLpNewAppeal]);

  const navObjects: Array<INavLinkOption> = [
    {
      key: "1",
      path: Pathnames.lpAuditsList,
      param: "audit",
      title: translate("Audit"),
      active: Pathnames.lpAuditsList === currentComponent,
      root: `${allRoutesNames.lp.root}`
    },
    {
      key: "2",
      path: Pathnames.lpActionPlansList,
      param: "actionPlans",
      title: translate("Action Plans"),
      active: Pathnames.lpActionPlansList === currentComponent,
      root: `${allRoutesNames.lp.root}`
    },
    {
      key: "3",
      path: Pathnames.lpAppealsList,
      param: "appeals",
      title: translate("Appeals"),
      active: Pathnames.lpAppealsList === currentComponent,
      root: `${allRoutesNames.lp.root}`
    },
    {
      key: "4",
      path: Pathnames.lpReportsList,
      param: "reports",
      title: translate("Reports"),
      active: Pathnames.lpReportsList === currentComponent,
      root: `${allRoutesNames.lp.root}`
    }
  ];
  const storeNavObj = navObjects.slice(1, 4);
  const dispNav = useMemo(() => {
    if (user?.roles.includes(Roles.AreaTrainer)) {
      return [];
    } else if (isIndividualLogin) {
      return navObjects;
    } else return storeNavObj;
  }, [isIndividualLogin, currentComponent]);
  const dispComponent = useMemo(() => {
    if (currentComponent) {
      switch (currentComponent) {
        case Pathnames.lpAuditsList:
          return (
            <Audit
              translate={translate}
              history={history}
              user={user}
              isIndividualLogin={isIndividualLogin}
            />
          );
        case Pathnames.lpActionPlansList:
          return (
            <ActionPlans
              translate={translate}
              history={history}
              user={user}
              currentUserRoles={user && user.roles}
              individualLogin={checkAllowedRoles(user?.roles)}
            />
          );
        case Pathnames.lpAppealsList:
          return (
            <LpAppeals
              translate={translate}
              user={user}
              history={history}
              currentUserRoles={user && user.roles}
              setModalState={setModalState}
              modalVisible={modalVisible}
              handleAuth={handleAppealSubmit}
            />
          );
        case Pathnames.lpReportsList:
          return <Reports translate={translate} history={history} user={user} />;
        default:
          return <PageNotFound />;
      }
    }
  }, [currentComponent, modalVisible]);

  if (lpAppealLoading.createLpNewAppeal) {
    return <Loader type="component" />;
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <>
        <View>
          <Text
            textStyle={styles.headerTitle}
            testId={testIds.lp.dashboardHeader}
            textSize={TextSize.ExtraLarge}
            fontWeight={FontWeight.SemiBold}
          >
            {translate("LP")}
          </Text>
          <Text
            textStyle={styles.locationTitle}
            testId={testIds.lp.storeName}
            textSize={TextSize.Large}
            fontWeight={FontWeight.Regular}
          >
            {user && user.roles.includes(Roles.StoreAccess)
              ? translate("Location", { name: user.storeName })
              : translate("hello", { name: user && user.name })}
          </Text>
        </View>
        <NavButtons
          navObjects={dispNav}
          history={history}
          setCurrentComponent={setCurrentComponent}
          containerStyle={styles.navContainer}
        />
        {dispComponent}
        {showSCErrorModal ? (
          <AlertModal
            setModalVisible={() => {
              showSCErrorModal ? setShowSCErrorModal(!showSCErrorModal) : null;
            }}
            errorMsg={showSCErrorModal ? validations.notAValidUser : "Error Downloading File"}
          />
        ) : null}
      </>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  navContainer: {
    flexDirection: "row",
    marginTop: 30
  },
  headerTitle: {
    color: colorPallete.black3,
    marginBottom: 6
  },
  locationTitle: {
    textTransform: "capitalize",
    color: colorPallete.grey1
  }
});

const mapStateToProps = (state: IState) => {
  return {
    checkAuth: AuditsSelectors.checkLpAuditAuth(state),
    error: AuditsSelectors.auditError(state),
    loading: AuditsSelectors.auditLoading(state),
    createLpNewAppealResponse: LpAppealsSelectors.createlpNewAppealResponse(state),
    lpAppealError: LpAppealsSelectors.lpAppealsError(state),
    lpAppealLoading: LpAppealsSelectors.lpAppealsLoading(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...LpAppealsAction, ...AuditActions }, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
