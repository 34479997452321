import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  LpAppealCountModel,
  LpSetAppealDecisionModel,
  LpNewAppealsTable,
  // InProcessAppealTableModel,
  LpCreateNewAppeal,
  LpAppealsCategoryDataModel,
  LpAppealCommentsDataModel,
  LpAppealsAccordionDataModel
} from "@timhortons/common/src/models/lp/appeals";
import { ILpCreateNewAppeals } from "@timhortons/common/src/modules/lp/components/dashboard/index";
import { LpInProcesssClosedTable } from "@timhortons/common/src/models/lp/appeals";

const inProcessSetLpAppealFilter = (data: {
  userId: number;
  selectedValues: any;
}): IFluxStandardAction<{ userId: number; selectedValues: any }> => {
  return {
    type: LP.SET_LP_APPEAL_FILTER.LOADING,
    payload: data
  };
};

const inProcessSetLpAppealFilterSuccess = (
  data: LpInProcesssClosedTable[]
): IFluxStandardAction<LpInProcesssClosedTable[]> => {
  return {
    type: LP.SET_LP_APPEAL_FILTER.SUCCESS,
    payload: data
  };
};

const inProcessSetLpAppealFilterError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.SET_LP_APPEAL_FILTER.ERROR,
    payload: error
  };
};

const getLpInProcessAppealsData = (data: {
  storeId: number;
  status: string;
}): IFluxStandardAction<{
  storeId: number;
  status: string;
}> => {
  return {
    type: LP.Appeals.GET_LP_IN_PROCESS_APPEALS_DATA.LOADING,
    payload: data
  };
};
const getLpInProcessAppealsSuccess = (
  data: LpInProcesssClosedTable[]
): IFluxStandardAction<LpInProcesssClosedTable[]> => {
  return {
    type: LP.Appeals.GET_LP_IN_PROCESS_APPEALS_DATA.SUCCESS,
    payload: data
  };
};
const getLpInProcessAppealsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.Appeals.GET_LP_IN_PROCESS_APPEALS_DATA.ERROR,
    payload: error
  };
};

const getLpAppealsCountData = (data: {
  appealerId: string;
  isIndividual: boolean;
}): IFluxStandardAction<{ appealerId: string; isIndividual: boolean }> => {
  return {
    type: LP.Appeals.GET_LP_APPEALS_COUNT.LOADING,
    payload: data
  };
};
const getLpAppealsCountDataSuccess = (
  data: LpAppealCountModel
): IFluxStandardAction<LpAppealCountModel> => {
  return {
    type: LP.Appeals.GET_LP_APPEALS_COUNT.SUCCESS,
    payload: data
  };
};

const getLpAppealsCountDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.Appeals.GET_LP_APPEALS_COUNT.ERROR,
    payload: error
  };
};

const getLpNewAppealsData = (data: {
  storeId: number;
  status: string;
}): IFluxStandardAction<{
  storeId: number;
  status: string;
}> => {
  return {
    type: LP.Appeals.GET_LP_NEW_APPEALS_DATA.LOADING,
    payload: data
  };
};
const getLpNewAppealsSuccess = (
  data: LpNewAppealsTable[]
): IFluxStandardAction<LpNewAppealsTable[]> => {
  return {
    type: LP.Appeals.GET_LP_NEW_APPEALS_DATA.SUCCESS,
    payload: data
  };
};
const getLpNewAppealsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.Appeals.GET_LP_NEW_APPEALS_DATA.ERROR,
    payload: error
  };
};

const createLpNewAppeal = (data: ILpCreateNewAppeals): IFluxStandardAction<ILpCreateNewAppeals> => {
  return {
    type: LP.Appeals.CREATE_LP_NEW_APPEAL.LOADING,
    payload: data
  };
};
const createLpNewAppealSuccess = (
  data: LpCreateNewAppeal
): IFluxStandardAction<LpCreateNewAppeal> => {
  return {
    type: LP.Appeals.CREATE_LP_NEW_APPEAL.SUCCESS,
    payload: data
  };
};
const createLpNewAppealError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.Appeals.CREATE_LP_NEW_APPEAL.ERROR,
    payload: error
  };
};
const getLpClosedAppealsData = (data: {
  storeId: number;
  status: string;
}): IFluxStandardAction<{
  storeId: number;
  status: string;
}> => {
  return {
    type: LP.Appeals.GET_LP_CLOSED_APPEALS_DATA.LOADING,
    payload: data
  };
};

const setLpAppealDecision = (
  data: LpSetAppealDecisionModel
): IFluxStandardAction<LpSetAppealDecisionModel> => {
  return {
    type: LP.SET_LP_APPEAL_DECISON_DATA.LOADING,
    payload: data
  };
};
const setLpAppealDecisionSuccess = (data: any): IFluxStandardAction<any> => {
  return {
    type: LP.SET_LP_APPEAL_DECISON_DATA.SUCCESS,
    payload: data
  };
};
const setLpAppealDecisionError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.SET_LP_APPEAL_DECISON_DATA.ERROR,
    payload: error
  };
};

const getLpClosedAppealsSuccess = (
  data: LpInProcesssClosedTable[]
): IFluxStandardAction<LpInProcesssClosedTable[]> => {
  return {
    type: LP.Appeals.GET_LP_CLOSED_APPEALS_DATA.SUCCESS,
    payload: data
  };
};
const getLpClosedAppealsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.Appeals.GET_LP_CLOSED_APPEALS_DATA.ERROR,
    payload: error
  };
};

const getLpAppealsCategoryData = (data: {
  appealId: number;
  version: string;
}): IFluxStandardAction<{ appealId: number; version: string }> => {
  return {
    type: LP.Appeals.GET_LP_APPEALS_CATEGORY_DATA.LOADING,
    payload: data
  };
};
const getLpAppealsCategoryDataSuccess = (
  data: LpAppealsCategoryDataModel
): IFluxStandardAction<LpAppealsCategoryDataModel> => {
  return {
    type: LP.Appeals.GET_LP_APPEALS_CATEGORY_DATA.SUCCESS,
    payload: data
  };
};
const getLpAppealsCategoryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.Appeals.GET_LP_APPEALS_CATEGORY_DATA.ERROR,
    payload: error
  };
};
const resetLpAppealError = () => {
  return {
    type: LP.Appeals.RESET_LP_APPEAL_ERROR
  };
};

const postLpAppealCommentsData = (value: {
  data: LpSetAppealDecisionModel;
  employeeId: string;
  version: string;
}): IFluxStandardAction<{
  data: LpSetAppealDecisionModel;
  employeeId: string;
  version: string;
}> => {
  return {
    type: LP.Appeals.LP_POST_APPEAL_COMMENT_DATA.LOADING,
    payload: value
  };
};
const postLpAppealCommentsDataSuccess = (data: LpAppealCommentsDataModel) => {
  return {
    type: LP.Appeals.LP_POST_APPEAL_COMMENT_DATA.SUCCESS,
    payload: data
  };
};
const postLpAppealCommentsDataError = (error: Error) => {
  return {
    type: LP.Appeals.LP_POST_APPEAL_COMMENT_DATA.ERROR,
    payload: error
  };
};

const getLpAppealAccordionData = (data: {
  appealId: string;
  categoryId: string;
  version: string;
}): IFluxStandardAction<{ categoryId: string; appealId: string; version: string }> => {
  return {
    type: LP.Appeals.LP_GET_APPEAL_ACCORDION_DATA.LOADING,
    payload: data
  };
};
const getLpAppealAccordionDataSuccess = (
  data: LpAppealsAccordionDataModel[]
): IFluxStandardAction<LpAppealsAccordionDataModel[]> => {
  return {
    type: LP.Appeals.LP_GET_APPEAL_ACCORDION_DATA.SUCCESS,
    payload: data
  };
};
const getLpAppealAccordionDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.Appeals.LP_GET_APPEAL_ACCORDION_DATA.ERROR,
    payload: error
  };
};
export const LpAppealsAction = {
  setLpAppealDecision,
  setLpAppealDecisionError,
  setLpAppealDecisionSuccess,
  getLpAppealsCountData,
  getLpAppealsCountDataError,
  getLpAppealsCountDataSuccess,
  createLpNewAppeal,
  createLpNewAppealSuccess,
  createLpNewAppealError,
  getLpNewAppealsData,
  getLpNewAppealsSuccess,
  getLpNewAppealsError,
  getLpAppealsCategoryData,
  getLpAppealsCategoryDataSuccess,
  getLpAppealsCategoryDataError,
  resetLpAppealError,
  getLpInProcessAppealsData,
  getLpInProcessAppealsSuccess,
  getLpInProcessAppealsError,
  inProcessSetLpAppealFilter,
  inProcessSetLpAppealFilterError,
  inProcessSetLpAppealFilterSuccess,
  getLpClosedAppealsData,
  getLpClosedAppealsSuccess,
  getLpClosedAppealsError,
  postLpAppealCommentsData,
  postLpAppealCommentsDataSuccess,
  postLpAppealCommentsDataError,
  getLpAppealAccordionData,
  getLpAppealAccordionDataSuccess,
  getLpAppealAccordionDataError
};
