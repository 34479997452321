import React, { ReactElement, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LpIndividualTable from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable/inProcessAppealTable";
import { StyleSheet, View } from "react-native";
import { checkAllowedRoles } from "@timhortons/common/src/utils/checkLoginRoles";
import { Roles } from "@timhortons/common/src/constants/roles";
import { colorPallete } from "@timhortons/common/src/assets/styles/colors";
import Loader from "@timhortons/common/src/components/molecules/loader";
import LpAppealHeader from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/appealHeader";
import LpNewAppealTable from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/newAppealTable";
import { LpAppealCountModel, LpNewAppealsTable } from "@timhortons/common/src/models/lp/appeals";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { useFocusedHook } from "@timhortons/common/src/utils/customhooks";
import AlertModal from "@timhortons/common/src/components/molecules/alertModal";
import { oopsCookie } from "@timhortons/common/src/assets/images/imageAssets";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import { IState } from "@timhortons/common/src/store/interfaces";
import { LpAppealsSelectors } from "@timhortons/common/src/redux/lp/selectors/appeals";
import { LpAppealsAction } from "@timhortons/common/src/redux/lp/actions/appeals";
import {
  ILpAppealsError,
  ILpAppealsLoading
} from "@timhortons/common/src/redux/lp/reducers/appeals";
import { LpInProcesssClosedTable } from "@timhortons/common/src/models/lp/appeals";
import LpInProcessAppealTable from "@timhortons/common/src/modules/lp/components/dashboard/appeals/organisms/lpInProcessAppealTable";
interface IProps {
  setLpAppealDecision: Function;
  inProcessSetLpAppealFilter: Function;
  individualInprocess: LpInProcesssClosedTable[];
  translate?: any;
  user?: IUserDetails;
  lpAppealCount: LpAppealCountModel;
  lpNewAppealsTableData: LpNewAppealsTable[];
  currentUserRoles?: Roles[];
  getLpAppealsCountData: (param: { appealerId: string; isIndividual: boolean }) => void;
  getLpNewAppealsData: Function;
  getAppealAccordionData: Function;
  setAppealDecision: Function;
  getInProcessAppealsData: Function;
  modalVisible?: boolean;
  setModalState?: () => void;
  handleAuth?: Function;
  loading: ILpAppealsLoading;
  error: ILpAppealsError;
  lpInProcessAppeals: LpInProcesssClosedTable[];
  lpClosedAppeals: LpInProcesssClosedTable[];
  getLpInProcessAppealsData: Function;
  getLpClosedAppealsData: Function;
  history: any;
}

export enum LpAppealTable {
  newAppeal = "New Appeal",
  inProcess = "In Process",
  closedAppeal = "Closed Appeal"
}

function LpAppeals(props: IProps): ReactElement {
  const {
    inProcessSetLpAppealFilter,
    individualInprocess,
    setLpAppealDecision,
    translate,
    user,
    //history,
    modalVisible,
    setModalState,
    currentUserRoles,
    handleAuth,
    loading,
    error,
    lpAppealCount,
    getLpNewAppealsData,
    lpNewAppealsTableData,
    getLpAppealsCountData,
    lpInProcessAppeals,
    lpClosedAppeals,
    getLpInProcessAppealsData,
    getLpClosedAppealsData
  } = props;

  const [currentTable, setCurrentTable] = useState<LpAppealTable>(LpAppealTable.newAppeal);
  const [isIndividualLogin, setIndividualLogin] = useState<boolean>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const isFocused = useFocusedHook();
  console.log(lpInProcessAppeals, "sdfasdfadsfasdf");

  useEffect(() => {
    if (checkAllowedRoles(currentUserRoles)) {
      setIndividualLogin(true);
      setCurrentTable(LpAppealTable.inProcess);
      getLpAppealsCountData({ appealerId: user.empId, isIndividual: true });
    } else if (currentUserRoles !== null) {
      getLpAppealsCountData({
        appealerId: user.storeCode.toString(),
        isIndividual: false
      });
      setIndividualLogin(false);
    }
  }, [currentUserRoles, isFocused]);
  useEffect(() => {
    if (currentTable === LpAppealTable.inProcess) {
      isIndividualLogin &&
        inProcessSetLpAppealFilter({
          userId: user.empId,
          selectedValues: {
            appealStatus: "IN_PROCESS",
            appealItemStatus: "IN_PROCESS",
            fromDate: null,
            endDate: null
          }
        });
    } else if (currentTable === LpAppealTable.closedAppeal) {
      isIndividualLogin &&
        inProcessSetLpAppealFilter({
          userId: user.empId,
          selectedValues: {
            appealStatus: "CLOSED",
            fromDate: null,
            endDate: null
          }
        });
    }
  }, [currentTable]);
  // const sortHandler = (val: string, columnName: string) => {
  // };
  function loader(): JSX.Element {
    return <Loader type="component" />;
  }
  if (loading.lpClosedData && loading.lpFilteredData) {
    return loader();
  }

  useEffect(() => {
    return () => {
      // clear error state
      setShowError(false);
    };
  }, []);
  useEffect(() => {
    if (error.setLpAppealsDecision) {
      setShowError(true);
    }
  }, [error.setLpAppealsDecision]);
  return (
    <>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <LpAppealHeader
            userDetails={user}
            isIndividualLogin={isIndividualLogin}
            setAppealFilter={inProcessSetLpAppealFilter}
            translate={translate}
            currentTable={currentTable}
            setCurrentTable={setCurrentTable}
            lpAppealCount={lpAppealCount}
          />
        </View>
        <View style={styles.tableContainer}>
          {currentUserRoles !== null ? (
            isIndividualLogin ? (
              <LpIndividualTable
                userDetails={user}
                loading={loading}
                setAppealDecision={setLpAppealDecision}
                isIndividualLogin={isIndividualLogin}
                translate={translate}
                currentTable={currentTable}
                appealsTableData={individualInprocess}
                // handleSortingOrder={sortHandler}
                error={error}
              />
            ) : isIndividualLogin === false && currentTable === LpAppealTable.newAppeal ? (
              <LpNewAppealTable
                lpNewAppealsTableData={lpNewAppealsTableData}
                translate={translate}
                onNewAppealClick={handleAuth}
                modalVisible={modalVisible}
                setModalState={setModalState}
                lpAppealCount={lpAppealCount && lpAppealCount.newAppealsCount}
                getLpNewAppeals={getLpNewAppealsData}
                loading={loading}
                error={error}
              />
            ) : (
              <LpInProcessAppealTable
                userDetails={user}
                isIndividualLogin={false}
                translate={translate}
                currentTable={currentTable}
                appealsTableData={
                  currentTable === LpAppealTable.inProcess ? lpInProcessAppeals : lpClosedAppeals
                }
                appealsTableDataLoading={
                  currentTable === LpAppealTable.inProcess // change after current table logic is done
                    ? loading.getLpInProcessAppeals
                    : loading.getLpClosedAppeals
                }
                error={
                  currentTable === LpAppealTable.inProcess // change after current table logic is done
                    ? error.getLpInProcessAppeals
                    : error.getLpClosedAppeals
                }
                getInProcessAppealsData={getLpInProcessAppealsData}
                getClosedAppealsData={getLpClosedAppealsData}
              />
            )
          ) : null}
        </View>
      </View>
      {showError && (
        <AlertModal
          setModalVisible={() => setShowError(false)}
          errorImage={error.setLpAppealsDecision !== null && oopsCookie}
          errorMsg={error.setLpAppealsDecision !== null && translate("errorSetAppealDecision")}
        />
      )}
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    marginTop: 30
  },
  headerContainer: {
    // header container styles goes here
  },
  tableContainer: {
    // new appeals table styles goes here
    flex: 1,
    // marginVertical: PlatformUtils.isWeb() ? 16 : 0,
    marginTop: PlatformUtils.isWeb() ? 16 : 12,
    borderRadius: 8,
    backgroundColor: colorPallete.white3,
    borderColor: colorPallete.grey19,
    borderWidth: 1
  }
});
const mapStateToProps = (state: IState) => {
  return {
    loading: LpAppealsSelectors.lpAppealsLoading(state),
    error: LpAppealsSelectors.lpAppealsError(state),
    lpInProcessAppeals: LpAppealsSelectors.getLpInProcessAppeals(state),
    lpClosedAppeals: LpAppealsSelectors.getLpClosedAppeals(state),
    individualInprocess: LpAppealsSelectors.lpAppealFilteredData(state),
    lpAppealCount: LpAppealsSelectors.lpAppealsCountData(state),
    lpNewAppealsTableData: LpAppealsSelectors.getLpNewAppealsData(state)
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return { ...bindActionCreators({ ...LpAppealsAction }, dispatch) };
};
export default connect(mapStateToProps, mapDispatchToProps)(LpAppeals);
