import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { LpAppealRepository } from "@timhortons/common/src/repositories/lp/appeals";
import { AppealCountModel, InProcesssClosedTable } from "@timhortons/common/src/models/rev/appeals";
import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import { Logger } from "@timhortons/common/src/services/logger";
import { LocalStorage } from "@timhortons/common/src/services/storage/storageService";
import { IUserDetails } from "@timhortons/common/src/modules/iam/redux/reducer/reducer";
import PlatformUtils from "@timhortons/common/src/utils/platformUtils";
import { LpAppealsAction } from "@timhortons/common/src/redux/lp/actions/appeals";
import {
  LpInProcesssClosedTable,
  LpAppealCountModel,
  LpCreateNewAppeal,
  LpAppealsCategoryDataModel,
  LpNewAppealsTable,
  // LpInProcesssClosedTable,
  // LpNewAppealsTable,
  PostLpAppealCommentsModel,
  LpAppealCommentsDataModel,
  LpAppealsAccordionDataModel
} from "@timhortons/common/src/models/lp/appeals";
import { ILpCreateNewAppeals } from "@timhortons/common/src/modules/lp/components/dashboard";

function* setLpAppealDecisionWorkerSaga(value: any) {
  const { payload } = value;
  let data;
  let user: IUserDetails = yield LocalStorage.getStorage("user");
  data = user.empId;
  let version = payload.get("version");
  if (version) {
    payload.delete("version");
  }
  try {
    const response: { status: string; data: any } = yield call(
      LpAppealRepository.setLpAppealDecision,
      { data: payload, version: version }
    );
    yield put(LpAppealsAction.setLpAppealDecisionSuccess(response));
    let body = payload;
    yield put(
      LpAppealsAction.getLpAppealsCountData({ appealerId: user.empId, isIndividual: true })
    );
    if (
      (PlatformUtils.isWeb() && payload.get("commentByAuditor") !== " ") ||
      PlatformUtils.isMobile()
    ) {
      yield put(
        LpAppealsAction.inProcessSetLpAppealFilter({
          userId: Number(data),
          selectedValues: {
            appealStatus: "IN_PROCESS",
            appealItemStatus: "IN_PROCESS",
            fromDate: null,
            endDate: null
          }
        })
      );
    }
  } catch (error) {
    Logger.error("lp-appeals", error, "setLpAppealDecisionWorkerSaga");

    yield put(LpAppealsAction.setLpAppealDecisionError(error));
  }
}

function* setLpAppealFilterWorkerSaga(
  value: IFluxStandardAction<{ userId: number; selectedValues: any }>
) {
  const { payload } = value;
  try {
    const response: InProcesssClosedTable[] = yield call(LpAppealRepository.getLpAppeals, payload);
    yield put(LpAppealsAction.inProcessSetLpAppealFilterSuccess(response));
  } catch (error) {
    Logger.error("lp-appeals", error, "setLpAppealFilterWorkerSaga");

    yield put(LpAppealsAction.inProcessSetLpAppealFilterError(error));
  }
}

function* getLpInProcessAppealsWorkerSaga(
  value: IFluxStandardAction<{
    storeId: number;
    status: string;
  }>
) {
  const { payload } = value;
  try {
    const response: LpInProcesssClosedTable[] = yield call(
      LpAppealRepository.getLpInProcessAppeals,
      payload
    );
    yield put(LpAppealsAction.getLpInProcessAppealsSuccess(response));
  } catch (error) {
    Logger.error("lp-appeals", error, "getLpInProcessAppealsWorkerSaga");

    yield put(LpAppealsAction.getLpInProcessAppealsError(error));
  }
}

function* getLpClosedAppealsWorkerSaga(
  value: IFluxStandardAction<{
    storeId: number;
    status: string;
  }>
) {
  const { payload } = value;
  try {
    const response: LpInProcesssClosedTable[] = yield call(
      LpAppealRepository.getLpInProcessAppeals,
      payload
    );
    yield put(LpAppealsAction.getLpClosedAppealsSuccess(response));
  } catch (error) {
    Logger.error("lp-appeals", error, "getLpClosedAppealsWorkerSaga");

    yield put(LpAppealsAction.getLpClosedAppealsError(error));
  }
}
function* getLpAppealsCountWorkerSaga(
  value: IFluxStandardAction<{ appealerId: string; isIndividual: boolean }>
) {
  const { payload } = value;
  try {
    const response: LpAppealCountModel = yield call(LpAppealRepository.getLpAppealsCount, payload);
    yield put(LpAppealsAction.getLpAppealsCountDataSuccess(response));
  } catch (error) {
    Logger.error("lp-appeals", error, "getLpAppealsCountWorkerSaga");

    yield put(LpAppealsAction.getLpAppealsCountDataError(error));
  }
}
function* getLpNewAppealsWorkerSaga(
  value: IFluxStandardAction<{
    storeId: number;
    status: string;
  }>
) {
  const { payload } = value;
  try {
    const response: LpNewAppealsTable[] = yield call(LpAppealRepository.getLpNewAppeals, payload);
    yield put(LpAppealsAction.getLpNewAppealsSuccess(response));
  } catch (error) {
    Logger.error("lp-appeals", error, "getLpNewAppealsWorkerSaga");

    yield put(LpAppealsAction.getLpNewAppealsError(error));
  }
}

function* createLpNewAppealWorkerSaga(value: IFluxStandardAction<ILpCreateNewAppeals>) {
  const { payload } = value;
  try {
    let data: LpCreateNewAppeal = yield call(LpAppealRepository.createLpAppeal, payload);
    data.appealerId = payload.auditorId;
    data.version = payload.version;
    yield put(LpAppealsAction.createLpNewAppealSuccess(data));
  } catch (error) {
    Logger.error("lp-appeals", error, "createLpNewAppealWorkerSaga");

    yield put(LpAppealsAction.createLpNewAppealError(error));
  }
}

function* getLpAppealsCategoryDataWorkerSaga(
  value: IFluxStandardAction<{ appealId: number; version: string }>
) {
  const { payload } = value;
  try {
    const response: LpAppealsCategoryDataModel = yield call(
      LpAppealRepository.getLpAppealsCategoryData,
      payload
    );
    yield put(LpAppealsAction.getLpAppealsCategoryDataSuccess(response));
  } catch (error) {
    Logger.error("lp-appeals", error, "getLpAppealsCategoryDataWorkerSaga");

    yield put(LpAppealsAction.getLpAppealsCategoryDataError(error));
  }
}
function* postLpAppealsCommentDataWorkerSaga(
  value: IFluxStandardAction<{
    body: PostLpAppealCommentsModel;
    employeeId: string;
    version: string;
  }>
) {
  try {
    const { payload } = value;
    const response: LpAppealCommentsDataModel = yield call(
      LpAppealRepository.postAppealsCommentData,
      payload
    );
    yield put(LpAppealsAction.postLpAppealCommentsDataSuccess(response));
  } catch (error) {
    Logger.error("lp-appeals", error, "postLpAppealsCommentDataWorkerSaga");

    yield put(LpAppealsAction.postLpAppealCommentsDataError(error));
  }
}

function* getLpAppealAccordianWorkerSaga(
  value: IFluxStandardAction<{ appealId: number; categoryId: number; version: string }>
) {
  const { payload } = value;
  try {
    const response: LpAppealsAccordionDataModel[] = yield call(
      LpAppealRepository.getLpAppealAccordionData,
      payload
    );
    yield put(LpAppealsAction.getLpAppealAccordionDataSuccess(response));
  } catch (error) {
    Logger.error("lp-appeals", error, "getLpAppealAccordianWorkerSaga");

    yield put(LpAppealsAction.getLpAppealAccordionDataError(error));
  }
}

function* lpAppealsWatcherSaga() {
  yield takeLatest(
    LP.Appeals.GET_LP_IN_PROCESS_APPEALS_DATA.LOADING,
    getLpInProcessAppealsWorkerSaga
  );
  yield takeLatest(LP.Appeals.GET_LP_CLOSED_APPEALS_DATA.LOADING, getLpClosedAppealsWorkerSaga);
  yield takeLatest(LP.SET_LP_APPEAL_DECISON_DATA.LOADING, setLpAppealDecisionWorkerSaga);
  yield takeEvery(LP.SET_LP_APPEAL_FILTER.LOADING, setLpAppealFilterWorkerSaga);
  yield takeLatest(LP.GET_LP_APPEALS_COUNT.LOADING, getLpAppealsCountWorkerSaga);
  yield takeLatest(LP.GET_LP_NEW_APPEALS_DATA.LOADING, getLpNewAppealsWorkerSaga);
  yield takeLatest(LP.Appeals.CREATE_LP_NEW_APPEAL.LOADING, createLpNewAppealWorkerSaga);
  yield takeLatest(
    LP.Appeals.GET_LP_APPEALS_CATEGORY_DATA.LOADING,
    getLpAppealsCategoryDataWorkerSaga
  );
  yield takeLatest(
    LP.Appeals.LP_POST_APPEAL_COMMENT_DATA.LOADING,
    postLpAppealsCommentDataWorkerSaga
  );
  yield takeLatest(LP.Appeals.LP_GET_APPEAL_ACCORDION_DATA.LOADING, getLpAppealAccordianWorkerSaga);
}

export default lpAppealsWatcherSaga;
