import { Any, JsonObject, JsonProperty } from "json2typescript";
import { LpActionPlanImagesModel } from "@timhortons/common/src/models/lp/actionPlan";

@JsonObject("LpCreateNewAppeal")
export class LpCreateNewAppeal {
  @JsonProperty("status", String)
  status: string = "";
  @JsonProperty("data", Number)
  data: number = 0;
  @JsonProperty("appealerId", Number)
  appealerId: number = 0;
  @JsonProperty("version", String)
  version: string = "";
}

@JsonObject("SetLpAppealDecisionModel")
export class SetLpAppealDecisionModel {
  @JsonProperty("body", FormData)
  body: FormData = null;
}

@JsonObject("LpObservations")
export class LpObservations {
  @JsonProperty("observation", String)
  observation: string = "";
  @JsonProperty("observationId", Number)
  observationId: Number = null;
}
@JsonObject("LpAppealsQuestionListModel")
export class LpAppealsQuestionListModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("questionLabel", String)
  serialNumber: string = "";
  @JsonProperty("question", String)
  questionName: string = "";
  @JsonProperty("subCategoryId", String)
  subCategoryId: string = "";
  @JsonProperty("categoryId", String)
  categoryId: string = "";
  @JsonProperty("observations", [LpObservations] && null)
  observations?: LpObservations[] = null;
  @JsonProperty("images", [LpActionPlanImagesModel] && null)
  file?: Array<LpActionPlanImagesModel> = [];
  @JsonProperty("comment", String && null)
  auditorComment?: string = "";
}
@JsonObject("InProcessCommentDataModel")
export class InProcessCommentDataModel {
  @JsonProperty("comment", String && null)
  comment: string = "";
  @JsonProperty("assets", [{ id: Number, signedUrl: String }] && [])
  assets: Array<{ id: number; signedUrl: string }> = [];
  @JsonProperty("hasAppealed", Boolean && null)
  hasAppealed: boolean = false;
}
@JsonObject("LpSubCategoryModel")
export class LpSubCategoryModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("subcategoryName", String)
  subcategoryName: string = "";
  @JsonProperty("subcategoryLabel", String)
  subcategoryLabel: string = "";
}

@JsonObject("LpFileModel")
export class LpFileModel {
  @JsonProperty("id", String && null)
  id: string = "";
  @JsonProperty("signedUrl", String && null)
  signedUrl: string = "";
  @JsonProperty("blob", Blob, true)
  blob?: Blob = null;
  @JsonProperty("observations", [LpObservations] && null)
  observations?: LpObservations[] = null;
  @JsonProperty("images", [LpActionPlanImagesModel] && null)
  file?: Array<LpActionPlanImagesModel> = [];
  @JsonProperty("comment", String && null)
  auditorComment?: string = "";
}

@JsonObject("LpAppealerResponseModel")
export class LpAppealerResponseModel {
  @JsonProperty("comment", String && null)
  comment: string = "";
  @JsonProperty("hasAppealed", Boolean)
  hasAppealed: boolean = false;
  @JsonProperty("assets", [LpFileModel] && null)
  file?: Array<LpFileModel> = [];
}

@JsonObject("LpAppealsAccordionDataModel")
export class LpAppealsAccordionDataModel {
  @JsonProperty("subCategoryLabel", String)
  serialNumber: string = "";
  @JsonProperty("subCategoryName", String)
  title: string = "";
  @JsonProperty("subCategoryId", Number)
  id: string = "";
  @JsonProperty("categoryId", Number)
  categoryId: string = "";
  @JsonProperty("questions", [LpAppealsQuestionListModel])
  opportunityList: Array<LpAppealsQuestionListModel> = [];
  @JsonProperty("appealerResponse", LpAppealerResponseModel && null)
  appealerResponse: LpAppealerResponseModel = null;
  @JsonProperty("status", Any, true)
  status: any = null;
}

@JsonObject("LpNewAppealsTable")
export class LpNewAppealsTable {
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("auditId", Number)
  auditId: number = 0;
  @JsonProperty("auditorId", Number)
  auditorId: number = 0;
  @JsonProperty("appealId", null)
  appealId: null;
  @JsonProperty("auditorName", String)
  auditorName: string = "";
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("auditDate", String)
  auditDate: string = "";
  @JsonProperty("appealedItems", null)
  appealedItems: null;
  @JsonProperty("auditVersion", String && null)
  version: string = "";
}

@JsonObject("LpAppealsCategoryData")
export class LpAppealsCategoryData {
  @JsonProperty("categoryId", Number)
  id: number = 0;
  @JsonProperty("categoryName", String)
  title: string = "";
  @JsonProperty("hasPendingAppeal", Boolean)
  progress: boolean = false;
  @JsonProperty("categoryLabel", String, true)
  catgeoryLabel: string = "";
}

@JsonObject("LpAppealsCategoryDataModel")
export class LpAppealsCategoryDataModel {
  @JsonProperty("storeCode", Number)
  storeId: number = 0;
  @JsonProperty("location", String)
  location: string = "";
  @JsonProperty("submittedBy", String)
  auditorName: string = "";
  @JsonProperty("completedPercentage", Number, true)
  completionPercentage?: number = 0;
  @JsonProperty("categories", [LpAppealsCategoryData])
  categories: Array<LpAppealsCategoryData> = null;
}

@JsonObject("LpSetAppealDecisionModel")
export class LpSetAppealDecisionModel {
  // @JsonProperty("opportunityList", [OpportunityListModel])
  // opportunityList: Array<OpportunityListModel> = null;
  // @JsonProperty("approveDecision", String)
  // approveDecision?: string = "";
  // @JsonProperty("disapproveDecision", Boolean)
  // disapproveDecision?: boolean = false;
  // @JsonProperty("auditedBy", String)
  // auditedBy?: string = "";
  @JsonProperty("body", FormData)
  body: FormData = null;
}
@JsonObject("LpAppealCountModel")
export class LpAppealCountModel {
  @JsonProperty("newAppealsCount", Number, true)
  newAppealsCount?: number = 0;
  @JsonProperty("inProcessCount", Number)
  inProcessCount: number = 0;
  @JsonProperty("closedCount", Number)
  closedAppealCount: number = 0;
}

//=============================================================================
@JsonObject("LpInProcessCommentDataModel")
export class LpInProcessCommentDataModel {
  @JsonProperty("comment", String && null)
  comment: string = "";
  @JsonProperty("assets", [{ id: Number, signedUrl: String }] && [])
  assets: Array<{ id: number; signedUrl: string }> = [];
  @JsonProperty("hasAppealed", Boolean && null)
  hasAppealed: boolean = false;
}

@JsonObject("LpOpportunityNewModel")
export class LpOpportunityNewModel {
  @JsonProperty("question", String)
  question: string = "";
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("isTicked", Boolean)
  isTicked: boolean = false;
}
@JsonObject("LpIndividualAppealItemModel")
export class LpIndividualAppealItemModel {
  @JsonProperty("appealItemId", Number)
  appealItemId: number = 0;
  @JsonProperty("appealItemStatus", String)
  appealItemStatus: string = "";
  @JsonProperty("hasAppealed", Boolean)
  hasAppealed: boolean = false;
  @JsonProperty("auditorResponse", LpInProcessCommentDataModel)
  auditorResponse: LpInProcessCommentDataModel = null;
  @JsonProperty("appealerResponse", LpInProcessCommentDataModel)
  appealerResponse: LpInProcessCommentDataModel = null;
  @JsonProperty("appealQuestion", [LpOpportunityNewModel], false)
  appealQuestion?: LpOpportunityNewModel[] = null;
  @JsonProperty("lpSubCategory", LpSubCategoryModel)
  lpSubCategory: LpSubCategoryModel;
}
@JsonObject("LpInProcesssClosedTable")
export class LpInProcesssClosedTable {
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("auditorName", String)
  auditorName: string = "";
  @JsonProperty("appealSubmittedByName", String)
  appealSubmittedByName: string = "";
  @JsonProperty("appealId", Number)
  appealId: number = 0;
  @JsonProperty("auditedBy", String)
  auditedBy: string = "";
  @JsonProperty("appealDate", String)
  appealDate: string = "";
  @JsonProperty("items", [LpIndividualAppealItemModel])
  items: LpIndividualAppealItemModel[] = null;
  @JsonProperty("appealApprovedByName", String && null)
  appealApprovedByName: string = "";
  @JsonProperty("auditVersion", String && null)
  auditVersion: string = "";
}
@JsonObject("LpCategoryDataModel")
export class LpCategoryDataModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("title", String)
  title: string = "";
  @JsonProperty("progress", Number)
  progress: number = 0;
  @JsonProperty("categoryLabel", String)
  label: string = "";
}

@JsonObject("LpCategoryStatusModel")
export class LpCategoryStatusModel {
  @JsonProperty("actionPlanId", String)
  actionPlanId: string = null;
  @JsonProperty("categoryTableItems", [LpCategoryDataModel])
  categoryTableItems: Array<LpCategoryDataModel> = null;
}

export class LpAppealItemModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("appealId", Number)
  appealId: number = 0;
  @JsonProperty("subCategoryId", Number)
  subCategoryId: number = 0;
  @JsonProperty("commentByAppealer", String && null)
  commentByAppealer: string = "";
  @JsonProperty("commentByAuditor", null)
  commentByAuditor: null;
  @JsonProperty("hasAppealed", Boolean)
  hasAppealed: boolean = false;
  @JsonProperty("appealItemAssets", [])
  appealItemAssets: [];
}

@JsonObject("PostLpAppealCommentsModel")
export class PostLpAppealCommentsModel {
  @JsonProperty("body", FormData)
  body?: FormData = null;
}

@JsonObject("LpAppealCommentsDataModel")
export class LpAppealCommentsDataModel {
  @JsonProperty("categoryId", Number)
  categoryId: number = 0;
  @JsonProperty("isExist", Boolean)
  isExist: boolean = false;
  @JsonProperty("appealItem", [])
  appealItem: [];
}

@JsonObject("LpSubCategoryInfoModel")
export class LpSubCategoryInfoModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("maxPoints", Number)
  points: number = 0;
  @JsonProperty("questions", [LpAppealsQuestionListModel])
  questionList: LpAppealsQuestionListModel[];
  @JsonProperty("subcategoryLabel", String)
  serialNumber: string = "";
  @JsonProperty("subcategoryName", String)
  title: string = "";
  @JsonProperty("status", Any, true)
  status: any = null;
  @JsonProperty("appealerResponse", Any, true)
  appealerResponse: any = null;
}
