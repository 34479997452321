import { IFluxStandardAction } from "@timhortons/common/src/store/interfaces";
import {
  FilterDataModel,
  LpAcknowledgementModel,
  LpActionPlanAccordionDataModel,
  LpActionPlanCategoryDataModel,
  LpActionPlanTableModel,
  LpPostActionstepsModel,
  LpSelectedFilterDataModel
} from "@timhortons/common/src/models/lp/actionPlan";
import { LP } from "@timhortons/common/src/redux/lp/actions/actionTypes";
import { ILPActionPlanData } from "@timhortons/common/src/modules/lp/components/dashboard/actionPlans";
import { LpActionPlanHeaderModel } from "@timhortons/common/src/models/lp/actionPlan";
const getLpActionPlans = (data: ILPActionPlanData): IFluxStandardAction<ILPActionPlanData> => {
  return {
    type: LP.ActionPlans.GET_LP_ACTION_PLANS.LOADING,
    payload: data
  };
};
const getLpActionPlansSuccess = (
  data: LpActionPlanTableModel
): IFluxStandardAction<LpActionPlanTableModel> => {
  return {
    type: LP.ActionPlans.GET_LP_ACTION_PLANS.SUCCESS,
    payload: data
  };
};
const getLpActionPlansError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.ActionPlans.GET_LP_ACTION_PLANS.ERROR,
    payload: error
  };
};
const getLpSelectedFilterData = (
  data: LpSelectedFilterDataModel
): IFluxStandardAction<LpSelectedFilterDataModel> => {
  return {
    type: LP.ActionPlans.GET_LP_SELECTED_FILTER_DATA,
    payload: data
  };
};
const getLpFilterData = (data: {
  storeId: string;
  empId: number;
  isIndividualLogin: boolean;
}): IFluxStandardAction<{ storeId: string; empId: number; isIndividualLogin: boolean }> => {
  return {
    type: LP.ActionPlans.GET_LP_FILTER_DATA.LOADING,
    payload: data
  };
};
const getLpFilterDataSuccess = (data: FilterDataModel): IFluxStandardAction<FilterDataModel> => {
  return {
    type: LP.ActionPlans.GET_LP_FILTER_DATA.SUCCESS,
    payload: data
  };
};
const getLpFilterDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.ActionPlans.GET_LP_FILTER_DATA.ERROR,
    payload: error
  };
};
// const updateLpActionPlanStatus = (data: {
//   actionPlanId: number;
// }): IFluxStandardAction<{ actionPlanId: number }> => {
//   return {
//     type: LP.ActionPlans.UPDATE_LP_ACTION_PLAN_STATUS.LOADING,
//     payload: data
//   };
// };
// const updateLpActionPlanStatusSuccess = (): IFluxStandardAction => {
//   return {
//     type: LP.ActionPlans.UPDATE_LP_ACTION_PLAN_STATUS.SUCCESS
//   };
// };
// const updateLpActionPlanStatusError = (error: Error): IFluxStandardAction<Error> => {
//   return {
//     type: LP.ActionPlans.UPDATE_LP_ACTION_PLAN_STATUS.ERROR,
//     payload: error
//   };
// };

const getLpActionPlanAccordionData = (data: {
  categoryId: number;
  actionPlanId: number;
  auditId: number;
  version?: string;
}): IFluxStandardAction<{ categoryId: number }> => {
  return {
    type: LP.ActionPlans.GET_LP_ACTION_PLAN_ACCORDION_DATA.LOADING,
    payload: data
  };
};
const getLpActionPlanAccordionDataSuccess = (
  data: LpActionPlanAccordionDataModel
): IFluxStandardAction<LpActionPlanAccordionDataModel> => {
  return {
    type: LP.ActionPlans.GET_LP_ACTION_PLAN_ACCORDION_DATA.SUCCESS,
    payload: data
  };
};
const getLpActionPlanAccordionDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.ActionPlans.GET_LP_ACTION_PLAN_ACCORDION_DATA.ERROR,
    payload: error
  };
};

const getLpActionPlanDetails = (data: {
  actionPlanId: number;
  version?: string;
}): IFluxStandardAction<{ actionPlanId: number }> => {
  return {
    type: LP.ActionPlans.GET_LP_ACTION_PLAN_HEADER_DATA.LOADING,
    payload: data
  };
};
const getLpActionPlanDetailSuccess = (
  data: LpActionPlanHeaderModel
): IFluxStandardAction<LpActionPlanHeaderModel> => {
  return {
    type: LP.ActionPlans.GET_LP_ACTION_PLAN_HEADER_DATA.SUCCESS,
    payload: data
  };
};
const getLpActionPlanDetailError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.ActionPlans.GET_LP_ACTION_PLAN_HEADER_DATA.ERROR,
    payload: error
  };
};

const getLpCategoryData = (data: {
  actionPlanId: number;
  auditId: number;
  version?: string;
}): IFluxStandardAction<{ actionPlanId: number; auditId: number }> => {
  return {
    type: LP.ActionPlans.GET_LP_CATEGORY_TABLE_DATA.LOADING,
    payload: data
  };
};
const getLpCategoryDataSuccess = (
  data: LpActionPlanCategoryDataModel[]
): IFluxStandardAction<LpActionPlanCategoryDataModel[]> => {
  return {
    type: LP.ActionPlans.GET_LP_CATEGORY_TABLE_DATA.SUCCESS,
    payload: data
  };
};
const getLpCategoryDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.ActionPlans.GET_LP_CATEGORY_TABLE_DATA.ERROR,
    payload: error
  };
};
const getLpOwnerData = (data: { storeId: string }): IFluxStandardAction<{ storeId: string }> => {
  return {
    type: LP.ActionPlans.GET_LP_OWNER_DATA.LOADING,
    payload: data
  };
};
const getLpOwnerDataSuccess = (
  data: LpAcknowledgementModel
): IFluxStandardAction<LpAcknowledgementModel> => {
  return {
    type: LP.ActionPlans.GET_LP_OWNER_DATA.SUCCESS,
    payload: data
  };
};
const getLpOwnerDataError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.ActionPlans.GET_LP_OWNER_DATA.ERROR,
    payload: error
  };
};

const postLpActionSteps = (data: {
  data: FormData;
  version?: string;
}): IFluxStandardAction<{ data: FormData; version?: string }> => {
  return {
    type: LP.ActionPlans.POST_LP_ACTION_STEPS.LOADING,
    payload: data
  };
};

const postLpActionStepsSuccess = (
  data: LpPostActionstepsModel
): IFluxStandardAction<LpPostActionstepsModel> => {
  return {
    type: LP.ActionPlans.POST_LP_ACTION_STEPS.SUCCESS,
    payload: data
  };
};

const postLpActionStepsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.ActionPlans.POST_LP_ACTION_STEPS.ERROR,
    payload: error
  };
};
const updateLpActionSteps = (data: {
  subCategoryId: number;
  actionPlanId: number;
  body: FormData;
  version?: string;
}): IFluxStandardAction<{
  subCategoryId: number;
  actionPlanId: number;
  body: FormData;
  version?: string;
}> => {
  return {
    type: LP.ActionPlans.UPDATE_LP_ACTION_STEPS.LOADING,
    payload: data
  };
};

const updateLpActionStepsSuccess = (
  data: LpPostActionstepsModel
): IFluxStandardAction<LpPostActionstepsModel> => {
  return {
    type: LP.ActionPlans.UPDATE_LP_ACTION_STEPS.SUCCESS,
    payload: data
  };
};

const updateLpActionStepsError = (error: Error): IFluxStandardAction<Error> => {
  return {
    type: LP.ActionPlans.UPDATE_LP_ACTION_STEPS.ERROR,
    payload: error
  };
};
const resetLpActionPlan = () => {
  return {
    type: LP.ActionPlans.RESET_ACTION_PLAN
  };
};

export const LPActionPlansAction = {
  getLpActionPlans,
  getLpActionPlansSuccess,
  getLpActionPlansError,
  getLpSelectedFilterData,
  getLpFilterData,
  getLpFilterDataSuccess,
  getLpFilterDataError,
  getLpActionPlanDetails,
  getLpActionPlanDetailSuccess,
  getLpActionPlanDetailError,
  getLpCategoryData,
  getLpCategoryDataSuccess,
  getLpCategoryDataError,
  getLpActionPlanAccordionData,
  getLpActionPlanAccordionDataSuccess,
  getLpActionPlanAccordionDataError,
  // updateLpActionPlanStatus,
  // updateLpActionPlanStatusSuccess,
  // updateLpActionPlanStatusError,
  resetLpActionPlan,
  getLpOwnerData,
  getLpOwnerDataSuccess,
  getLpOwnerDataError,
  postLpActionSteps,
  postLpActionStepsSuccess,
  postLpActionStepsError,
  updateLpActionSteps,
  updateLpActionStepsSuccess,
  updateLpActionStepsError
};
