import { Any, JsonObject, JsonProperty } from "json2typescript";

@JsonObject("LpActionPlanImagesModel")
export class LpActionPlanImagesModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("signedUrl", String)
  signedUrl: string = "";
}
@JsonObject("LpActionPlanObservationModel")
export class LpActionPlanObservationModel {
  @JsonProperty("observationId", Number)
  observationId: number = 0;
  @JsonProperty("observaton", String)
  observation: string = "";
}
@JsonObject("LpQuestionListModel")
export class LpQuestionListModel {
  @JsonProperty("id", Number)
  id: number = 0;
  @JsonProperty("questionLabel", String)
  serialNumber: string = "";
  @JsonProperty("question", String)
  questionName: string = "";
  @JsonProperty("comment", String && null)
  comment: string = "";
  @JsonProperty("categoryId", Number)
  categoryId: number = 0;
  @JsonProperty("subCategoryId", Number)
  subCategoryId: number = 0;
  @JsonProperty("images", [LpActionPlanImagesModel] && null)
  files: Array<LpActionPlanImagesModel> = [];
  @JsonProperty("observations", Any && null)
  observations: any = [];
}
@JsonObject("LpFileModel")
export class LpFileModel {
  @JsonProperty("id", String)
  id: string = "";
  @JsonProperty("signedUrl", String)
  signedUrl: string = "";
  @JsonProperty("blob", Blob, true)
  blob?: Blob = null;
}
@JsonObject("LpCommentDataModel")
export class LpCommentDataModel {
  @JsonProperty("subCategoryId", Number)
  subCategoryId?: number = 0;
  @JsonProperty("auditId", Number)
  auditId?: number = 0;
  @JsonProperty("comment", String && null)
  comment: string = "";
  @JsonProperty("deletedImage", [String], true)
  deletedImage?: string[] = [];
  @JsonProperty("images", [LpFileModel])
  files: LpFileModel[] = [];
}
@JsonObject("LpActionPlanAccordionDataModel")
export class LpActionPlanAccordionDataModel {
  @JsonProperty("actionSteps", String && null)
  actionSteps: string = "";
  @JsonProperty("assignedTo", String && null)
  assignedTo: string = "";
  @JsonProperty("endDate", String && null)
  endDate: string = "";
  @JsonProperty("itemStatus", String && null)
  status: string = "";
  @JsonProperty("images", [LpActionPlanImagesModel] && null)
  files: Array<LpActionPlanImagesModel> = [];
  @JsonProperty("categoryId", Number)
  categoryId: number = 0;
  @JsonProperty("subCategoryId", Number)
  subCategoryId: number = 0;
  @JsonProperty("questions", [LpQuestionListModel])
  opportunityList: Array<LpQuestionListModel> = null;
  @JsonProperty("subCategoryLabel", String)
  serialNumber: string = "";
  @JsonProperty("subCategoryName", String)
  title: string = "";
}
@JsonObject("LpPostActionstepsModel")
export class LpPostActionstepsModel {
  @JsonProperty("nonConformanceComment", String && null)
  actionSteps: string = "";
  @JsonProperty("assignTo", String && null)
  assignedTo: string = "";
  @JsonProperty("endDate", String && null)
  endDate: string = "";
  @JsonProperty("itemStatus", String && null)
  status: string = "";
  @JsonProperty("images", [LpActionPlanImagesModel] && null)
  files: Array<LpActionPlanImagesModel> = [];
  @JsonProperty("categoryId", Number)
  categoryId: number = 0;
  @JsonProperty("subcategoryId", Number)
  subCategoryId: number = 0;
  @JsonProperty("completedPercentage", String && null)
  completionPercent: string = "";
  @JsonProperty("inProgressPercentage", String && null)
  inProcessPercent: string = "";
  @JsonProperty("notStartedPercentage", String && null)
  notStartedPercent: string = "";
}

@JsonObject("LpActionPlanModel")
export class LpActionPlanModel {
  @JsonProperty("storeName", String && null)
  storeName: string = "";
  @JsonProperty("storeCode", Number)
  storeId: number = 0;
  @JsonProperty("auditedOn", String)
  auditDate: Date = new Date();
  @JsonProperty("auditorName", String && null)
  auditBy: string = "";
  @JsonProperty("auditorId", Number)
  auditorId: number = 0;
  @JsonProperty("actionPlanId", Number)
  actionPlanId: number = 0;
  @JsonProperty("actionPlanCompletionPercentage", Number)
  completionPercentage: number = 0;
  @JsonProperty("auditId", Number)
  auditId: number = 0;
  @JsonProperty("planStatus", String)
  status: string = "";
  @JsonProperty("auditVersion", String && null)
  version: string = "";
}

@JsonObject("LpActionPlanHeaderModel")
export class LpActionPlanHeaderModel {
  @JsonProperty("auditedOn", String)
  auditDate: string = "";
  @JsonProperty("planStatus", String)
  planStatus: string = "";
  @JsonProperty("storeCode", Number)
  storeCode: number = 0;
  @JsonProperty("auditorName", String)
  auditedBy: string = "";
  @JsonProperty("auditorId", String)
  auditorId: string = "";
  @JsonProperty("auditId", Number)
  auditId: number = 0;
  @JsonProperty("actionPlanId", Number)
  actionPlanId: number = 0;
  @JsonProperty("completedPercentage", Number)
  completionPercent: number = 0;
  @JsonProperty("inProgressPercentage", Number)
  inProcessPercent: number = 0;
  @JsonProperty("notStartedPercentage", Number)
  notStartedPercent: number = 0;
  @JsonProperty("storeName", String)
  storeName: string = "";
  @JsonProperty("completedCount", Number)
  completedItems: number = 0;
  @JsonProperty("inProgressCount", Number)
  inProcessItems: number = 0;
  @JsonProperty("notStartedCount", Number)
  notStartedItems: number = 0;
  @JsonProperty("actionPlanCompletionPercentage", Number)
  actionPlanCompletionPercentage: number = 0;
}

@JsonObject("LpActionPlanTableModel")
export class LpActionPlanTableModel {
  @JsonProperty("totalRecords", Number)
  totalRecords: number = 0;
  @JsonProperty("records", [LpActionPlanModel])
  actionPlans: LpActionPlanModel[] = null;
}

@JsonObject("LpSelectedFilterDataModel")
export class LpSelectedFilterDataModel {
  @JsonProperty("date", String)
  date: string = "";
  @JsonProperty("name", String)
  name: string = null;
  @JsonProperty("status", String)
  status: string = null;
  @JsonProperty("fromDate", Date)
  fromDate?: Date = null;
  @JsonProperty("toDate", Date)
  toDate?: Date = null;
  @JsonProperty("search", String)
  search?: string = null;
}
@JsonObject("AuditorNameModel")
export class AuditorNameModel {
  @JsonProperty("auditorId", String)
  auditorId: string = "";
  @JsonProperty("auditorName", String)
  auditorName: string = "";
}

@JsonObject("DropDownModel")
export class DropDownModel {
  @JsonProperty("value", String)
  value: string = "";
  @JsonProperty("label", String)
  label: string = "";
}
@JsonObject("FilterDataModel")
export class FilterDataModel {
  @JsonProperty("auditorName", AuditorNameModel)
  auditorName: AuditorNameModel[] = null;
  @JsonProperty("status", DropDownModel)
  status: DropDownModel[];
}

@JsonObject("LpActionPlanCategoryDataModel")
export class LpActionPlanCategoryDataModel {
  @JsonProperty("categoryId", Number)
  id: number = 0;
  @JsonProperty("categoryName", String)
  title: string = "";
  @JsonProperty("opportunityExists", Boolean)
  progress: boolean = false;
  @JsonProperty("categoryLabel", String)
  catgeoryLabel: string = "";
  categoryName: any;
}

@JsonObject("LpAckDropDownModel")
export class LpAckDropDownModel {
  @JsonProperty("name", String)
  value?: string = "";
  @JsonProperty("name", String)
  label?: string = "";
  @JsonProperty("employeeId", String)
  employeeId?: string = "";
}
@JsonObject("LpAcknowledgementModel")
export class LpAcknowledgementModel {
  @JsonProperty("LpdropDownData", LpAckDropDownModel)
  dropDownData: LpAckDropDownModel[];
}
